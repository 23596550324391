import React from 'react'
import { Navbar, ContactUs, Footer } from '../../sections'



const Contact = (props) => {
  return (
    <>
        <Navbar
            setName={props.setName}
        />
        <div className="mt-12"></div>
        <ContactUs class="md:h-screen flex justify-center justify-items-center items-center" />
        <Footer />
    </>
  )
}

export default Contact