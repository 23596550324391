import React from 'react'
import { ContactUs, Footer, Navbar } from '../../sections'

const PrivacyPolicy = (props) => {
  return (
  <>
    <Navbar
      setName={props.setName}
    />
    <div className="mt-14"></div>
    <section className="container mx-auto px-6 py-12 flex flex-col space-y-6">
			<div className="flex flex-col text-left">
				<p className="text-black text-lg">
          This Privacy Policy describes how PHATBAR (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Collecting Personal Information</h1>
				<p className="text-black text-lg">
          When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.<br/>
          <h2 className="font-bold text-xl">Device information</h2>
          <span className="font-bold">Examples of Personal Information collected:</span> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.<br/>
          <span className="font-bold">Purpose of collection:</span> to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.<br/>
          <span className="font-bold">Source of collection:</span> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels<br/> 
          <span className="font-bold">Disclosure for a business purpose:</span> shared with our processor Shopify<br/><br/>
          
          <h2 className="font-bold text-xl">Order information</h2>
          <span className="font-bold">Examples of Personal Information collected:</span> name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number.<br/>
          <span className="font-bold">Purpose of collection:</span> to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.<br/>
          <span className="font-bold">Source of collection:</span> collected from you.<br/>
          <span className="font-bold">Disclosure for a business purpose:</span> shared with our processor Shopify Customer support information<br/>
          <span className="font-bold">Purpose of collection:</span> to provide customer support.<br/>
          <span className="font-bold">Source of collection:</span> collected from you.<br/>
        </p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Minors</h1>
				<p className="text-black text-lg">
        The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Sharing Personal Information</h1>
				<p className="text-black text-lg">
          We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. 
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Using Personal Information</h1>
				<p className="text-black text-lg">
          We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Lawful basis</h1>
				<p className="text-black text-lg">
          Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:<br/>
          Your consent;<br/>
          The performance of the contract between you and the Site;<br/>
          Compliance with our legal obligations;<br/>
          To protect your vital interests;<br/>
          To perform a task carried out in the public interest;<br/>
          For our legitimate interests, which do not override your fundamental rights and freedoms.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Retention</h1>
				<p className="text-black text-lg">
          When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Automatic decision-making</h1>
				<p className="text-black text-lg">
          If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.<br/>
          We DO NOT engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.<br/>
          Our processor Shopify uses limited automated decision-making to prevent fraud that does not have a legal or otherwise significant effect on you.<br/>
          Services that include elements of automated decision-making include:<br/>
          Temporary denylist of IP addresses associated with repeated failed transactions. This denylist persists for a small number of hours.<br/>
          Temporary denylist of credit cards associated with denylisted IP addresses. This denylist persists for a small number of days.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Selling Personal Information</h1>
				<p className="text-black text-lg">
          Our Site sells Personal Information, as defined by the California Consumer Privacy Act of 2018 (“CCPA”).<br/>
          [INSERT:<br/>
          CATEGORIES OF INFORMATION SOLD;<br/>
          INSTRUCTIONS ON HOW TO OPT-OUT OF SALE;<br/>
          WHETHER YOUR BUSINESS SELLS INFORMATION OF MINORS (UNDER 16) AND WHETHER YOU OBTAIN AFFIRMATIVE AUTHORIZATION;<br/>
          IF YOU PROVIDE A FINANCIAL INCENTIVE TO NOT SELL INFORMATION, PROVIDE INFORMATION ABOUT WHAT THAT INCENTIVE IS.]
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Cookies</h1>
				<p className="text-black text-lg">
          A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.<br/>
          We use the following cookies to optimize your experience on our Site and to provide our services.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Do Not Track</h1>
				<p className="text-black text-lg">
          Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Changes</h1>
				<p className="text-black text-lg">
          We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
				</p>
			</div>
      <div className="flex flex-col text-left">
        <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">Contact</h1>
				<p className="text-black text-lg">
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at help@aroma-king.co.uk or by mail using the details provided below:<br/>
          If you are not satisfied with our response to your complaint, you have the right to lodge your complaint with the relevant data protection authority. You can contact your local data protection authority, or our supervisory authority here:  https://ico.org.uk/make-a-complaint<br/>
          Added!
				</p>
			</div>
    </section>
    <ContactUs />
    <Footer />
    </>
  )
}

export default PrivacyPolicy