import React from 'react'
import { vapeman } from '../../assets'
import { ContactUs, Footer, Navbar } from '../../sections'

const AboutUs = (props) => {
  return (
    <>
      <Navbar
        setName={props.setName}
      />
      <div className="mt-14"></div>
      <section className="container mx-auto md:px-6 py-12 flex flex-col md:flex-row space-y-4 md:space-x-4 md:items-center md:justify-center">
        <div className="flex flex-col text-left">
          <h1 className="text-3xl md:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">
            PhatBar  
          </h1>
          <p className="text-black text-md md:text-lg">
            PhatBar was founded in 2023 and is most recognised for its disposable vape devices, which have been painstakingly crafted with affection for adults who want to vape smarter. As more adult vapers discover that disposables give a pleasant vape experience, we want to present them with a great-tasting, practical, easy-to-use, and budget-friendly vape that is unequalled among e-cig manufacturers.<br/>
            Our PhatBar disposable vapes are more flavorful and less expensive. Because our products are built with quality-conscious vapers in mind, our culture is based on our devotion to high standards. Our dedication to quality extends from sourcing and manufacturing to marketing and distribution. We value true quality because we care about how our products touch the lives of those who use them.<br/>
            We think that as a business, we have a responsibility and an opportunity to help design a better future for our customers, workers, and stakeholders. We strongly advise you to switch to our collection of delectable e-liquids, which includes our ground-breaking PhatBar disposable vapes. If you’re seeking for incredibly delicious flavours to vape, PhatBar will not disappoint.
          </p>
        </div>   
        <img src={vapeman} className="fancy-border-radius rotate-lg-6 w-full shadow-lg dark:shadow-black/20 max-w-lg" alt="" />
      </section>
      <ContactUs />
      <Footer />
    </>
  )
}

export default AboutUs