import React, { useState } from 'react';
import Input from '../Input/Input';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const [fullname, setFullname] = useState(''); // Initialize name as an empty string
  const [tel, setTel] = useState(''); // Initialize tel as an empty string
  const [email, setEmail] = useState(''); // Initialize email as an empty string
  const [message, setMessage] = useState(''); // Initialize message as an empty string
  const [fullnameError, setFullnameError] = useState(null);
  const [telError, setTelError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [messageError, setMessageError] = useState(null);

  const validateInputs = () => {
    let isValid = true;

    if (!fullname.trim()) {
      setFullnameError('Fullname is required.');
      isValid = false;
    } else {
      setFullnameError('');
    }

    if (!tel.trim()) {
      setTelError('Tel is required.');
      isValid = false;
    } else if (!/^\d+$/.test(tel)) {
      setTelError('Tel must be a valid number.');
      isValid = false;
    } else {
      setTelError('');
    }

    if (!email.trim()) {
      setEmailError('Email is required.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!message.trim()) {
      setMessageError('Message is required.');
      isValid = false;
    } else {
      setMessageError('');
    }

    return isValid;
  };

  const emptyInputs = () => {
    setFullname('');
    setTel('');
    setEmail('');
    setMessage('');
  };

  const sendMessage = async () => {
    if (validateInputs()) {
      try {
        const response = await axios.post(
          'https://phatbar.store/api/api/send-message',
          {
            fullname,
            tel,
            email,
            message,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status !== 200) {
          console.log('Failed to fetch:', response.status);
          return;
        }

        const responseData = response.data;

        if (responseData.status === 200) {
          await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your message has been sent',
            showConfirmButton: false,
            timer: 1500,
          });
          emptyInputs();
        } else {
          await Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: responseData.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.log(error);
        await Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  return (
    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
      <form>
        <div className="relative mb-6" data-te-input-wrapper-init>
          <Input
            id="fullname"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            placeholder="Fullname"
          />
          {fullnameError && <p className="text-red-500">{fullnameError}</p>}
        </div>
        <div className="relative mb-6" data-te-input-wrapper-init>
          <Input
            id="tel"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            placeholder="Tel"
          />
          {telError && <p className="text-red-500">{telError}</p>}
        </div>
        <div className="relative mb-6" data-te-input-wrapper-init>
          <Input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
        </div>
        <div className="relative mb-6" data-te-input-wrapper-init>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 "
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Your message"
          />
          {messageError && <p className="text-red-500">{messageError}</p>}
        </div>
        <button
          type="button"
          onClick={sendMessage}
          data-te-ripple-init
          data-te-ripple-color="light"
          className="mb-6 inline-block w-full rounded bg-black px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black-600 focus:bg-black-600 focus:ring-0 active:bg-black"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
