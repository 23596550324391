import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Rating } from "@material-tailwind/react";
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
	Carousel,
	initTE,
} from "tw-elements";
// import { ProductSlideitem } from '../../components';


const AddToCart = () => {
    useEffect(() => {
		initTE({ Carousel });
        window.scrollTo(0, 0); // Scroll to top when the component is mounted
    }, []);
    const {id} = useParams();
    

	const [product, setProduct] = useState([]);
	const [loading, setLoading] = useState(true);
	const [user_id, setUserId] = useState(null); // Initialize user_id as null
	const [name, setName] = useState(null); // Initialize name as null
	const product_id = id;
	const [quantity, setQuantity] = useState(1);
	const [isLoading, setIsLoading] = useState(true);


	const handleIncrement = () => {
	  // Increase the quantity by 1
	  setQuantity(quantity + 1);
	};
  
	const handleDecrement = () => {
	  // Decrease the quantity by 1, but make sure it doesn't go below the minimum value (1 in this case)
	  if (quantity > 1) {
		setQuantity(quantity - 1);
	  }
	};
	
	useEffect(() => {
	  const fetchData = async () => {
		try {
		  const res = await axios.get(`https://phatbar.store/api/api/product/${id}`);
		  if (res.data.status === 200) {
			setLoading(false);
			setProduct(res.data.product);
		  }
		} catch (error) {
		  console.log(error);
		}
	  };
	  fetchData();
	}, [id]);
	
	let slider = "";
	if (loading) {
		slider = <h1 className="text-5xl flex items-center space-x-2 justify-center justify-items-center">
					<span>Loading</span>
					<svg class="animate-spin h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</h1>
	} else {
		slider = (
			<>
				{product.product_images.map((image) => (
					<img src={`https://phatbar.store/api/${image.image}`} className="rounded-xl" alt="Exotic Fruits" />
				))}
			</>
		);
	}
	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
	
		(async () => {
		  try {
			const response = await fetch('https://phatbar.store/api/api/user', {
			  headers: { 'Content-Type': 'application/json' },
			  credentials: 'include',
			});
	
			if (!response.ok) {
			  throw new Error('Network response was not ok');
			}
	
			const content = await response.json();
			setUserId(content.id);
			setName(content.name);
		  } catch (error) {
			console.error('Error fetching user data:', error);
		  } finally {
			setIsLoading(false);
		  }
		})();
	  }, []);
	


	const addToMyCart = async (e: SyntheticEvent) => {
		e.preventDefault();
	
		if (!name) {
			return navigate('/login');
		}
	
		try {
			const response = await axios.post('https://phatbar.store/api/api/addtocart', {
				user_id,
				name,
				product_id,
				quantity
			  }, {
				withCredentials: true  // Include credentials in the request
			  });
	
			if (response.status !== 200) {
				console.log('Failed to fetch:', response.status);
				return;
			}
	
			const responseData = response.data;
	
			if (responseData.status === 200) {
				await Swal.fire({
					position: 'top-end',
					icon: 'success',
					title: 'Product has been added to cart',
					showConfirmButton: false,
					timer: 1500
				});
				navigate('/cart');
			}
		} catch (error) {
			console.log(error)
			await Swal.fire({
				position: 'top-end',
				icon: 'warning',
				title: 'Error',
				showConfirmButton: false,
				timer: 1500
			});
			navigate('/');
			// console.error('Error:', error);
		}
	};
	
	
	
	if(isLoading)
	{
		return (	
			<div className="flex flex-col items-center justify-center justify-items-center text-center py-12">
				<h1 className="text-5xl">
					Loading ...
				</h1>
			</div>
		)
	}

	return (
		<section className="container mx-auto md:px-6 rounded-xl my-12 py-12 sm:py-16"> 
			<div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-10">

				<div className="lg:col-span-1">
					<div className="grid grid-cols-1 gap-4 overflow-hidden">
						
						{slider}

					</div>
				</div>
			

				<div className="lg:col-span-1">
					<h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">{product.name}</h1>

					<div className="mt-5 flex items-center">
						<div className="flex items-center">
							<svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
							</svg>
							<svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
							</svg>
							<svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
							</svg>
							<svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
							</svg>
							<svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
							</svg>
						</div>
						<p className="ml-2 text-sm font-medium text-gray-500">1,209 Reviews</p>
					</div>

					<h2 className="mt-8 text-base text-gray-900">
						{product.description}	
					</h2>

					<div className="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
						<div className="flex items-end">
							<h1 className="text-3xl font-bold">£ {product.price}</h1>
							<span className="text-base">/{product.name}</span>
						</div>
						<div className="flex items-center border-gray-100">
							<span className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50" onClick={handleDecrement} >-</span>
							<input className="h-8 w-8 border bg-white text-center text-xs outline-none" type="number" value={quantity} min="1" />
							<span className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50" onClick={handleIncrement}>+</span>
						</div>
						<button type="button" onClick={addToMyCart} className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-gray-900 bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
							<svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
							</svg>
							Add to cart
						</button>
					</div>

					<ul className="mt-8 space-y-4">
						<li className="flex items-center text-left text-sm font-medium text-gray-600">
							<svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" className=""></path>
							</svg>
							Free shipping worldwide
						</li>
						<li className="flex items-center text-left text-sm font-medium text-gray-600">
							<svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" className=""></path>
							</svg>
							Cancel Anytime
						</li>
						<Rating />
					</ul>
				</div>
			</div>
		</section>
	)
}

export default AddToCart