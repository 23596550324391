import React from 'react'

const Input = (props) => {
  return (

		<>
			<label htmlFor={props.id} className="logo">
				{props.label}
			</label>
			<input onChange={props.onChange} id={props.id} type={props.type} className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]" placeholder={props.placeholder} />

		</>

	)
}

export default Input