import { About, ContactUs, Footer, Hero, Navbar, Newsletter, Products, Testimonials, Whyus } from '../../sections'
import { AgeModal } from '../../components'

const Home = (props) => {



  return (
    <>
		<Navbar setName={props.setName} />
		<div className="mt-16"></div>
		<AgeModal />
		<Hero />
		<Products />
		<About />
		{/* <Testimonials /> */}
		<Whyus />
		<Newsletter />
		<ContactUs />
		<Footer />
    </>
  )
}

export default Home