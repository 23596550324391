import React from 'react'
import { Link } from 'react-router-dom'

const Product = (props) => {
  return (
    <Link to={`/product/${props.productId}`} className={`group ${props.class} text-center`}>
      <div className={`aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 ${props.class}`}>
        <img src={props.image} alt={props.name} className={`h-full w-full object-cover object-center group-hover:opacity-75 ${props.class}`} />
      </div>
			<h3 className={`mt-4 text-sm text-blue-900 ${props.class}`}>{props.name}</h3>
			<p className={`mt-1 text-lg font-medium text-blue-900 ${props.class}`}>{props.price}</p>
      <button className={`bg-slate-100 hover:bg-black hover:text-white w-full mt-4 px-4 py-2 rounded-lg text-lg text-black transition-all ${props.class}`}>
        Add to cart
      </button>
    </Link>
  )
}

export default Product