import React from 'react'

const Title = (props) => {
  return (
    <div className="py-2">
			<h1 className="text-center text-3xl font-semibold text-black logo">
				{props.name}
			</h1>
    </div>
  )
}

export default Title