import React from 'react'
import { Testimonial, Title } from '../../components'
import { person_1, person_2, person_3 } from '../../assets'

const Testimonials = () => {
  return (
		<div class="container my-24 mx-auto md:px-6">
			<section class="text-center">
				
				<Title name="Testimonials" />

				<div class="grid gap-x-6 md:grid-cols-3 lg:gap-x-12 mt-12">
					<Testimonial
						image={person_1}
						fullname="Jessica M"
						text="I stumbled upon PHATBAR while searching for a reliable vape supplier, and I'm glad I did. The variety of flavors they offer is astounding! From classic tobacco blends to exotic fruit fusions, I've found something for every mood. The quality of their vapes is top-notch, ensuring a smooth and satisfying experience every time. I'm a loyal customer now!"
					/>
					<Testimonial
						image={person_2}
						fullname="Sarah P"
						text="I've been vaping for years, and I've tried numerous brands. PHATBAR, hands down, offers the best bang for your buck. The craftsmanship of their vapes is evident in every puff, and the flavor profiles are unmatched. Their 'Blueberry Breeze' is a personal favorite; it's like a burst of summer in every inhale. PHATBAR has become my go-to for all things vape-related!"
					/>
					<Testimonial
						image={person_3}
						fullname="David L"
						text="Beyond the incredible selection of vapes and flavors, what truly sets PHATBAR apart is their exceptional customer service. I had a few questions about their products, and their team was not only quick to respond but incredibly knowledgeable. They helped me find the perfect vape and flavor to suit my preferences. I couldn't be happier with my purchase, and I'll definitely be coming back for more!"
					/>
				</div>
			</section>
		</div>
  )
}

export default Testimonials