import React from 'react'

const Error404Page = () => {
  return (
    <section className="h-screen flex flex-col justify-center justify-items-center items-center text-center">
        <h1 className="text-red-500 text-5xl">Eror 404</h1>
    </section>
  )
}

export default Error404Page