import { CartItems, ContactUs, Navbar } from '../../sections'

const Cart = (props) => {
  
  

  return (
    <div>
		  <Navbar
        setName={props.setName}
      />
  		<div className="mt-12"></div>
      <CartItems />
      <ContactUs />
    </div>
  )
}

export default Cart