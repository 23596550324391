import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Input } from '../../components';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import PayPalButton from '../../components/PayPalButton/PayPalButton';
import { ContactUs, Footer, Navbar } from '../../sections';

const CartItems = (props) => {
  let shippingCost = 4;
  const [cartItems, setCartItems] = useState(null);
  const [user_id, setUserId] = useState(null); // Initialize user_id as null
  const [name, setName] = useState(null); // Initialize name as null
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [totalAmount, setTotalAmount] = useState(0.0);

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [address, setAddress] = useState();
  const [tel, setTel] = useState();
  const [apartment, setApartment] = useState();
  const [city, setCity] = useState();
  const [postcode, setPostcode] = useState();

  const country = "United Kingdom";
  const [firstnameError, setFirstnameError] = useState();
  const [lastnameError, setLastnameError] = useState();
  const [addressError, setAddressError] = useState('');
  const [telError, setTelError] = useState('');
  const [apartmentError, setApartmentError] = useState();
  const [cityError, setCityError] = useState();
  const [postcodeError, setPostcodeError] = useState();

  const [order_number, setOrderNumber] = useState();
  const navigate = useNavigate();
  let totalChanged = false;

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const response = await fetch('https://phatbar.store/api/api/user', {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const content = await response.json();
        setUserId(content.id);
        setName(content.name);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // cart items count
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/get-cart-count', {
          user_id: user_id,
          name: name,
        }, {
          withCredentials: true,
        });

        setCartItemCount(response.data.count);
      } catch (error) {
        console.error('Error fetching cart count:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user_id && name) {
      fetchData();
    }
  }, [user_id, name]);

  // get cart items
  useEffect(() => {
    setIsLoading(true);

    const fetchCartData = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/get-my-cart', {
          user_id: user_id,
          name: name,
        }, {
          withCredentials: true,
        });

        setCartItems(response.data.cart);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user_id && name) {
      fetchCartData();
    }
  }, [user_id, name]);

  let data = "";
  let subtotal = 0;
  let oldsubtotal = 0;
  
  if (isLoading) {
    data = (
      <div className="flex flex-col items-center justify-center text-center py-20 mt-12">
        <h1 className="text-5xl">Loading ...</h1>
      </div>
    );
  } else if (cartItemCount > 0 && cartItems) { // Ensure cartItems is not null before mapping
    data = cartItems.map((item) => (
          <tr key={item.id} className="border-b dark:border-neutral-500">
            <td className="whitespace-nowrap px-6 py-4">{item.product_name}</td>
            <td className="whitespace-nowrap px-6 py-4">{item.quantity}</td>
            <td className="whitespace-nowrap px-6 py-4">${item.quantity >= 3 ? Math.floor(item.quantity / 3) * 10 + (item.quantity % 3) * item.product_price : item.product_price * item.quantity}</td>
          </tr>
    ));
    // Calculate subtotal only if cartItems is available
    oldsubtotal = cartItems.reduce((total, item) => total + item.product_price * item.quantity, 0);

    subtotal = cartItems.reduce((total, item) => {
      if (item.quantity >= 3) {
        totalChanged = true;
        return total + (Math.floor(item.quantity / 3) * 10) + (item.quantity % 3) * item.product_price;
      } else {
        return total + item.product_price * item.quantity;
      }
    }, 0);
    if(subtotal > 20)
    {
      shippingCost = 0;
    }
  } else {
    data = (
      <div className="flex flex-col items-center justify-center text-center py-20 mt-12">
        <h1 className="text-5xl">No Items found in your cart</h1>
      </div>
    );
  }

  // validateInputs
  const validateInputs = () => {
    let isValid = true;
    
    if (!firstname) {
      setFirstnameError('Firstname is required.');
      isValid = false;
    } else {
      setFirstnameError('');
    }

    if (!lastname) {
      setLastnameError('Lastname is required.');
      isValid = false;
    } else {
      setLastnameError('');
    }

    if (!address) {
      setAddressError('Address is required.');
      isValid = false;
    } else {
      setAddressError('');
    }

    if (!tel) {
      setTelError('Tel is required.');
      isValid = false;
    } else if (!/^\d+$/.test(tel)) {
      setTelError('Tel must be a valid number.');
      isValid = false;
    } else {
      setTelError('');
    }

    if (!apartment) {
      setApartmentError('Apartment is required.');
      isValid = false;
    } else {
      setApartmentError('');
    }

    if (!city) {
      setCityError('City is required.');
      isValid = false;
    } else {
      setCityError('');
    }

    if (!postcode) {
      setPostcodeError('Postcode is required.');
      isValid = false;
    }
    else if (!/^\d+$/.test(postcode)) {
      setTelError('Postcode must be a valid number.');
      isValid = false;
    }
    else {
      setPostcodeError('');
    }

    return isValid;
  };

  // generateOrderNumber
  useEffect(() => {

    if (cartItems) { // Add a check for cartItems here
      setTotalAmount(parseFloat(subtotal + shippingCost).toFixed(2));
    }

    const generateOrderNumber = () => {
      const timestamp = Date.now().toString(); // Get current timestamp
      const randomNum = Math.floor(Math.random() * 1000); // Generate random number between 0 and 999
      const orderNumber = timestamp + randomNum; // Concatenate timestamp and random number
      setOrderNumber(orderNumber);
    }

    generateOrderNumber();
  }, [cartItems, subtotal, shippingCost]);

  // place order
  const placeOrder = async () => {
    if (validateInputs()) {
      try {
        const response = await axios.post('https://phatbar.store/api/api/placeorder', {
          user_id,
          country,
          firstname,
          lastname,
          name,
          order_number,
          address,
          tel,
          apartment,
          city,
          postcode,
          totalAmount,
        }, {
          withCredentials: true // Include credentials in the request
        });

        if (response.status !== 200) {
          console.log('Failed to fetch:', response.status);
          return;
        }

        const responseData = response.data;

        if (responseData.status === 200) {
          await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your order has been placed',
            showConfirmButton: false,
            timer: 1500
          });
          navigate('/');
        } else {
          await Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: responseData.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      } catch (error) {
        console.log(error);
        await Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500
        });
        navigate('/');
      }
    }
    else{
      return 0;
    }
  };

  let totalCard = null;

	if (cartItemCount > 0) {
		totalCard = (
			<div className="mt-6 w-full h-auto flex flex-col rounded-lg border bg-white p-6 shadow-md md:mt-0">
				<div className="mb-2 flex justify-between">
					<p className="text-gray-700">Subtotal</p>
					<p className="text-gray-700">£{parseFloat(subtotal).toFixed(2)}</p>
				</div>
				<div className="flex justify-between">
					<p className="text-gray-700">Shipping</p>
					<p className="text-gray-700">£{shippingCost}</p>
				</div>
				<hr className="my-4" />
				<div className="flex justify-between">
					<p className="text-lg font-bold">Total</p>
					<div className="">
            {(totalChanged) &&
						  <del className="mb-1 text-lg font-bold text-red-600">£ {parseFloat(oldsubtotal + shippingCost).toFixed(2)}</del>
            }
						<p className="mb-1 text-lg font-bold">£ {totalAmount}</p>
						<p className="text-sm text-gray-700">including Shipping</p>
					</div>
				</div>
        <div className="flex flex-col space-y-3">
          {/* <button onClick={placeOrder} className="p-4 mt-6 text-center w-full text-lg rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
            Place order
          </button> */}
          {console.log(totalAmount)}
          <PayPalButton
            amount={totalAmount}
            onPaymentSuccess={placeOrder}
            validate={validateInputs}
          />
        </div>
			</div>
		)

	}

  const stripeCard = <>
                        <label for="card-holder" class="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                        <div class="relative">
                          <input type="text" id="card-holder" name="card-holder" class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Your full name here" />
                          <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                          </div>
                        </div>
                        <label for="card-no" class="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                        <div class="flex space-x-2">
                          <div class="relative w-7/12 flex-shrink-0">
                            <input type="text" id="card-no" name="card-no" class="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="xxxx-xxxx-xxxx-xxxx" />
                            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                              <svg class="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                              </svg>
                            </div>
                          </div>
                          <input type="text" name="credit-expiry" class="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="MM/YY" />
                          <input type="text" name="credit-cvc" class="w-1/6 flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="CVC" />
                        </div>
                        <button onClick={placeOrder} className="p-4 mt-6 text-center w-full text-lg rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
                          Buy now
                        </button>
                      </>

  return (
    <>
    <Navbar
      setName={props.setName}
    />
    <div className="mt-12"></div>
    <div className="h-auto bg-gray-100 py-12">
      <h1 className="mb-10 text-center text-2xl font-bold logo">
        Cart Items
      </h1>
      <div className="mx-auto justify-center px-6 md:flex md:space-x-6 xl:px-0">
        <div className="rounded-lg border h-auto md:w-1/2 bg-white p-6 shadow-md">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="">
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <label htmlFor="country" className="logo">
                      Country
                    </label>
                    <select className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-3 p-3 leading-[2.15] outline-none transition-all duration-200 ease-linear ">
                      <option className="bg-black text-white py-3">United Kingdom</option>
                    </select>
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="Firstname"
                      type="text"
                      label="Firstname"
                      onChange={(e) => setFirstname(e.target.value)}
                      placeholder="Firstname"
                    />
                    {firstnameError && <p className="text-red-500">{firstnameError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="Lastname"
                      type="text"
                      label="Lastname"
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Lastname"
                    />
                    {lastnameError && <p className="text-red-500">{lastnameError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="Address"
                      type="text"
                      label="Address"
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Address"
                    />
                    {addressError && <p className="text-red-500">{addressError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="Tel"
                      type="text"
                      label="Tel"
                      onChange={(e) => setTel(e.target.value)}
                      placeholder="Tel"
                    />
                    {telError && <p className="text-red-500">{telError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="apartment"
                      type="text"
                      label="Apartment"
                      onChange={(e) => setApartment(e.target.value)}
                      placeholder="Apartment, suite, etc (optional)"
                    />
                    {apartmentError && <p className="text-red-500">{apartmentError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="city"
                      type="text"
                      label="City"
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="City"
                    />
                    {cityError && <p className="text-red-500">{cityError}</p>}
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <Input
                      id="postcode"
                      type="text"
                      label="Postcode"
                      onChange={(e) => setPostcode(e.target.value)}
                      placeholder="Postcode"
                    />
                    {postcodeError && <p className="text-red-500">{postcodeError}</p>}
                  </div>
                </div>
                <div className="overflow-hidden">
                  <table className="min-w-full border text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr>
                        <th scope="col" className="px-6 py-4">Item</th>
                        <th scope="col" className="px-6 py-4">Quantity</th>
                        <th scope="col" className="px-6 py-4">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          {totalCard}
          {/* <div className="mt-6 h-auto flex flex-col rounded-lg border bg-white p-6 shadow-md md:mt-0">
            {stripeCard}
          </div> */}
        </div>
      </div>
    </div>
    <ContactUs />
    <Footer />
    </>
  );
};

export default CartItems;
