import React, { useEffect, useState } from 'react'
import { Product, Title } from '../../components'
import axios from 'axios';

const Products = () => {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://phatbar.store/api/api/products`);
        if (res.data.status === 200) {
          setLoading(false);
          setProducts(res.data.products);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  let productsData = "";
  if(loading)
  {
      productsData = <>
                        <div className="bg-slate-200 w-full py-10 animate-pulse rounded-lg">
                        </div>
                        <div className="bg-slate-200 w-full py-10 animate-pulse rounded-lg">
                        </div>
                        <div className="bg-slate-200 w-full py-10 animate-pulse rounded-lg">
                        </div>
                        <div className="bg-slate-200 w-full py-10 animate-pulse rounded-lg">
                        </div>
                        <div className="bg-slate-200 w-full py-10 animate-pulse rounded-lg">
                        </div>
                      </>
                          
                      
  }
  else {
    productsData = products.map((product) => {
      return (
          <Product
            key={product.id}
            productId={product.id}
            name={product.name}
            image={`https://phatbar.store/api/${product.product_images[0].image}`}
            price={`£${product.price}`}
          />
      )
  })
}

  return (
    <section id="products" className="">
      <Title name="see our products" />
      <div className="container mx-auto px-6 py-4">
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xl:gap-x-8">
          {productsData}
        </div>
      </div>
    </section>
  )
}

export default Products