import { AddToCart, ContactUs, Navbar, Newsletter, Testimonials, Whyus } from '../../sections'

const ProductPage = (props) => {



  return (
    <>
		<Navbar authenticated={props.username ? true:false} username={props.username ? props.username:null} setName={props.setName} cartItemCount={props.cartItemCount}/>
		<div className="mt-12"></div>
		<AddToCart user_id={props.user_id} username={props.username} />
		<Testimonials />
		<Whyus />
		<Newsletter />
		<ContactUs />
    </>
  )
}

export default ProductPage