import React from 'react'

const Slide = (props) => {
  return (
    <div className="relative">
        <img src={props.img} className="w-full rounded-lg" alt={props.key} />
    </div>
  )
}

export default Slide