import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import axios from 'axios';
import Slide from "../../components/Slide/Slide";


const Hero = () => {

  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://phatbar.store/api/api/slides`);
        if (res.data.status === 200) {
          setLoading(false);
          setSlides(res.data.slides);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  let slidesData = "";
  if(loading)
  {
      slidesData =  <div className="max-h-24 py-24 bg-slate-200 w-full animate-pulse rounded-lg">
                    </div>
  }
  else {
    slidesData = slides.map((slide) => {
      return (
          <Slide
            key={slide.id}
            img={`https://phatbar.store/api/${slide.image}`}
          />
      )
  })
}

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false
  };

  return (
    <div className="md:container md:mx-auto px-6 py-4">
      <Slider {...settings}>
        {slidesData}
      </Slider>
    </div>
  );
};

export default Hero;
