import React from 'react'
import { ContactForm } from '../../components'

const ContactUs = (props) => {
  return (
    <div className={`bg-slate-50 ${props.class} py-12`}>
      <section className="container mx-auto md:px-6">
        <div className="flex flex-wrap">
          <div className="mb-10 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
            <h2 className="mb-6 text-3xl font-bold logo">Contact us</h2>
            <p className="mb-6 text-neutral-500 dark:text-neutral-300">
            Feel free to reach out to us with any questions, concerns, or feedback you may have. We value your input and are here to assist you. You can get in touch with our dedicated support team through the provided contact form, or simply drop us an email at phatbar.official@gmail.com. We aim to respond promptly and ensure your shopping experience with us is exceptional. Thank you for choosing PHATBAR!
            </p>
            <a href="mailto:phatbar.official@gmail.com" className="mb-2 text-neutral-500 dark:text-neutral-300">
              phatbar.official@gmail.com
            </a>
          </div>
          <ContactForm />
        </div>
      </section>
    </div>
  )
}

export default ContactUs