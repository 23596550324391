import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButton = (props) => {


  const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: props.amount, // Use the amount prop for the order value
					},
				},
			],
		});
  };

  const onApprove = (data, actions) => {
    // Call the onPaymentSuccess prop after successful payment
    props.onPaymentSuccess(data);
  };

	let main = 	<button className="p-4 mt-6 text-center w-full text-lg rounded-md bg-yellow-600 py-1.5 font-medium text-black">
					PayPal
				</button>;
	if(props.validate())
	{
		main = 	<PayPalScriptProvider options={{ "client-id": "AaPJ-rcpm_Vw9MJNXaFgnoOgDnfY9WK0JTS4E-XiW8W4DGC__wgccKk_MUQnxFAPQxUgXaq0lqDAtX2a" }}>
					<PayPalButtons createOrder={createOrder} onApprove={onApprove} />
				</PayPalScriptProvider>
	}

  return (
    <>
		{main}
	</>
  );
};

export default PayPalButton;
