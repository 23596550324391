import React, { useEffect, useState } from 'react'
import { Collapse, Dropdown, initTE } from "tw-elements";
import { Link } from 'react-router-dom';
import axios from 'axios';


const Navbar = (props) => {


  const [user_id, setUserId] = useState();
  const [name, setName] = useState();
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const response = await fetch('https://phatbar.store/api/api/user', {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const content = await response.json();
        setUserId(content.id);
        setName(content.name);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/get-cart-count', {
          user_id: user_id,
          name: name,
        }, {
          withCredentials: true,
        });

        setCartItemCount(response.data.count);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user_id, name]);

  
  const logout = async () => {
    await fetch('https://phatbar.store/api/api/logout', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include'
    })
    props.setName('');
  }
  
  useEffect(() => {
    initTE({ Collapse, Dropdown });
  }, []);


  let profile = "";

  if(isLoading)
  {
    profile = <div className="relative" data-te-dropdown-ref>
                  <p className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90">
                    Loading ...
                  </p>
              </div>
  }
  else
  {
    if(name)
    {
      profile = "";
    }
    else
    {
      profile =  <>
                  <div className="relative" data-te-dropdown-ref>
                    <Link className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                          to="/register" data-te-nav-link-ref >
                      Sign Up
                    </Link>
                  </div>
                  <div className="relative" data-te-dropdown-ref>
                    <Link className="mx-4 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                          to="/login" data-te-nav-link-ref >
                      Sign In
                    </Link>
                  </div>
                </>
    }
  }



  return (
    <nav className="fixed top-0 flex w-full flex-wrap items-center z-30 justify-between bg-white py-2 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 lg:py-4" data-te-navbar-ref>
      <div className="md:container md:mx-auto px-6 flex w-full flex-wrap items-center justify-between">
        <button
          className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
          type="button" data-te-collapse-init data-te-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
          <span className="[&>svg]:w-7">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-7 w-7">
              <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clip-rule="evenodd" />
            </svg>
          </span>
        </button>
    
        <div className="!visible hidden flex-grow justify-items-center basis-[100%] items-center lg:!flex lg:basis-auto" id="navbarSupportedContent1" data-te-collapse-item>
          <a className="text-2xl font-bold flex mr-6 logo" href="# ">
            <span className="text-black">phat</span>
            <span className="text-black">bar</span>
          </a>
          <ul
            className="list-style-none mr-auto flex flex-col pl-0 lg:flex-row text-black"
            data-te-navbar-nav-ref>
            <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
              <Link
                className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400"
                to="/" data-te-nav-link-ref>
                  Home
              </Link>
            </li>
            {/* <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
              <Link
                className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                to="/products" data-te-nav-link-ref >
                  Products
              </Link>
            </li> */}
            <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
              <Link
                className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                to="/AboutUs" data-te-nav-link-ref >
                  About Us
              </Link>
            </li>
            <li className="mb-4 lg:mb-0 lg:pr-2" data-te-nav-item-ref>
              <Link
                className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                to="/Contact" data-te-nav-link-ref >
                  Contact
              </Link>
            </li>
          </ul>
        </div>
    
        <div className="relative flex items-center text-black">
          <Link
            className="mr-4 text-3xl transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  [&.active]:text-black/90 "
            to={!name ? '/login':'/cart'}>
            <svg role="presentation" stroke-width="1" focusable="false" width="32" height="32" class="icon icon-cart" viewBox="0 0 22 22">
              <path d="M11 7H3.577A2 2 0 0 0 1.64 9.497l2.051 8A2 2 0 0 0 5.63 19H16.37a2 2 0 0 0 1.937-1.503l2.052-8A2 2 0 0 0 18.422 7H11Zm0 0V1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </Link>
          <span className="absolute -mt-8 text-sm rounded-full bg-black px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white">
              {name ? cartItemCount : '..' || isLoading ? '..' : cartItemCount}
          </span>
    

          {profile}
          {name && (
            <div className="relative" data-te-dropdown-ref>
              <Link onClick={logout} className="transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none  lg:px-2 [&.active]:text-black/90 "
                  to="/login" data-te-nav-link-ref >
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navbar