import axios from 'axios';
import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { Input } from '../../components';


const Newsletter = () => {

  const [email, setEmail] = useState(''); // Initialize email as an empty string
  const [emailError, setEmailError] = useState(''); // Initialize email as an empty string
  
  const validateInputs = () => {
    let isValid = true;
    if (!email.trim()) {
      setEmailError('Email is required.');
      isValid = false;
    } else {
      setEmailError('');
    }
    return isValid;  // Return whether the form is valid or not
  };


  const subscribe = async () => {
    if (validateInputs()) {
      try {
        const response = await axios.post('https://phatbar.store/api/api/subscribe', { email });
  
        if (response.status === 200) {
          await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setEmail('');
        } else {
          await Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error(error);
        await Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  


  return (
    <div className="md:container py-12 md:mx-auto px-6">
      <section className="mb-32 text-center">
        <div className="flex flex-wrap justify-center">
          <div className="flex-basis w-full shrink-0 grow-0 md:w-10/12 md:px-3 lg:w-8/12 xl:w-6/12">
            <div className="mb-6 inline-block rounded-full bg-black p-4 shadow-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" className="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
              </svg>
            </div>
    
            <h2 className="mb-6 text-3xl font-bold">Subscribe to the newsletter</h2>
    
            <p className="mb-12 text-neutral-500">
              We will write rarely and only high-quality content.
            </p>
    
            <div className="mb-6 flex-row md:mb-0 md:flex">
              <div className="relative mb-3 w-full md:mr-3 md:mb-0" data-te-input-wrapper-init>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  type="email"
                  placeholder="email"
                />
                {emailError && <p className="text-red-500">{emailError}</p>}
              </div>
              <button onClick={subscribe} type="submit" className="inline-block rounded bg-black text-white hover:text-black px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-whitetransition duration-150 ease-in-out hover:bg-white hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-black active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]" data-te-ripple-init data-te-ripple-color="light">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Newsletter