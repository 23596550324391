import React from 'react'

const About = () => {
  return (

	<div className="container mx-auto md:py-12 md:px-6 py-9 px-4">
		<div className="flex flex-col md:flex-row justify-around">
			<div className="w-full flex flex-col justify-center">
				<h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 dark:text-white pb-4 logo">About Us</h1>
				<p className="font-normal text-lg text-gray-600">
					PHATBAR was founded in 2023 and is most recognised for its disposable vape devices, which have been painstakingly crafted with affection for adults who want to vape smarter. As more adult vapers discover that disposables give a pleasant vape experience, we want to present them with a great-tasting, practical, easy-to-use, and budget-friendly vape that is unequalled among e-cig manufacturers.<br/><br/>
					Our PHATBAR disposable vapes are more flavorful and less expensive. Because our products are built with quality-conscious vapers in mind, our culture is based on our devotion to high standards. Our dedication to quality extends from sourcing and manufacturing to marketing and distribution. We value true quality because we care about how our products touch the lives of those who use them.<br/>
					We think that as a business, we have a responsibility and an opportunity to help design a better future for our customers, workers, and stakeholders. We strongly advise you to switch to our collection of delectable e-liquids, which includes our ground-breaking PHATBAR disposable vapes. If you’re seeking for incredibly delicious flavours to vape, PHATBAR will not disappoint.
				</p>
			</div>
			<div className="w-full">
				<img className="rounded-xl w-full" src="https://vapingdaily.com/wp-content/uploads/2021/07/vaping-flavored-e-liquid-from-an-electronic-cigarette-image.jpg" alt="A group of People" />
			</div>
		</div>
	</div>
  )
}

export default About