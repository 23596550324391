import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AboutUs, Cart, Checkout, Contact, Error404Page, Home, PrivacyPolicy, ProductPage, SignIn, SignUp, TermsOfServices } from './pages';
import './App.css';
import './index.css';

const App = () => {
  const [user_id, setUserId] = useState();
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const response = await fetch('https://phatbar.store/api/user', {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const content = await response.json();
        setUserId(content.id);
        setName(content.name);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);




  return (
    <Router>
      {isLoading && <div className="loading-overlay">Loading...</div>}
      <Routes>
        <Route path="/" element={<Home setName={setName} />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/login" element={<SignIn setName={setName} />} />
        <Route path="/product/:id" element={<ProductPage setName={setName}/>} />
        <Route path="/cart" element={<Cart setName={setName} />} />
        <Route path="/checkout" element={<Checkout setName={setName} />} />
        <Route path="/error404" element={<Error404Page />} />
        <Route path="/TermsOfServices" element={<TermsOfServices setName={setName} />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy setName={setName} />} />
        <Route path="/AboutUs" element={<AboutUs setName={setName} />} />
        <Route path="/Contact" element={<Contact setName={setName} />} />
      </Routes>
    </Router>
  );
};

export default App;
