import React, { useEffect, useState } from 'react';
import { CartItem } from '../../components';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CartItems = () => {
  let shippingCost = 4;
  const [cartItems, setCartItems] = useState(null);
  const [user_id, setUserId] = useState(null); // Initialize user_id as null
  const [name, setName] = useState(null); // Initialize name as null
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  let totalChanged = false;
  

  // get user
  
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await fetch('https://phatbar.store/api/api/user', {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const content = await response.json();
        setUserId(content.id);
        setName(content.name);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);


  // get-cart-count
  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/get-cart-count', {
          user_id: user_id,
          name: name,
        }, {
          withCredentials: true,
        });

        setCartItemCount(response.data.count);
      } catch (error) {
        console.error('Error fetching cart count:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user_id && name) {
      fetchData();
    }
  }, [user_id, name]);

  // get cart items
  useEffect(() => {
    setIsLoading(true);

    const fetchCartData = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/get-my-cart', {
          user_id: user_id,
          name: name,
        }, {
          withCredentials: true,
        });

        setCartItems(response.data.cart);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user_id && name) {
      fetchCartData();
    }
  }, [user_id, name]);

  let data = "";
  let oldsubtotal = 0;
  let subtotal = 0;

  if (isLoading) {
    data = (
      <div className="flex flex-col items-center justify-center text-center py-20 mt-12">
        <h1 className="text-5xl flex items-center space-x-2 justify-center justify-items-center">
          <span>Loading</span>
          <svg class="animate-spin h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </h1>
      </div>
    );
  } else if (cartItemCount > 0 && cartItems) {
    data = cartItems.map((item) => (
      <CartItem
        username={name}
        user_id={user_id}
        cartItemId={item.cart_item_id}
        name={item.product_name}
        old_price={item.quantity >= 3 ? item.quantity * item.product_price : ''}
        price={item.quantity >= 3 ? Math.floor(item.quantity / 3) * 10 + (item.quantity % 3) * item.product_price : item.product_price * item.quantity}
        desc={item.product_desc}
        quantity={item.quantity}
        image={`https://phatbar.store/api/${item.product_image[0].image}`}
        key={item.cart_item_id}
      />
    ));
  
    oldsubtotal = cartItems.reduce((total, item) => total + item.product_price * item.quantity, 0);
    subtotal = cartItems.reduce((total, item) => {
      if (item.quantity >= 3) {
        totalChanged = true;
        return total + (Math.floor(item.quantity / 3) * 10) + (item.quantity % 3) * item.product_price;
      } else {
        return total + item.product_price * item.quantity;
      }
    }, 0);
    if(subtotal > 20)
    {
      shippingCost = 0;
    }
  }
  else {
    data = (
      <div className="flex flex-col items-center justify-center text-center py-20 mt-12">
        <h1 className="text-5xl">No Items found in your cart</h1>
      </div>
    );
  }

  	let totalCard = null;

	if (cartItemCount > 0) {
		totalCard = (
			<div className="h-full flex flex-col rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
				<div className="mb-2 flex justify-between">
					<p className="text-gray-700">Subtotal</p>
					<p className="text-gray-700">£{subtotal.toFixed(2)}</p>
				</div>
				<div className="flex justify-between">
					<p className="text-gray-700">Shipping</p>
					<p className="text-gray-700">£{shippingCost}</p>
				</div>
				<hr className="my-4" />
				<div className="flex justify-between">
					<p className="text-lg font-bold">Total</p>
					<div className="">
            {(totalChanged) &&
						  <del className="mb-1 text-lg font-bold text-red-600">£ {parseFloat(oldsubtotal + shippingCost).toFixed(2)}</del>
            }
						<p className="mb-1 text-lg font-bold">£ {(subtotal + shippingCost).toFixed(2)}</p>
						<p className="text-sm text-gray-700">including Shipping</p>
					</div>
				</div>
				<Link to='/checkout' className="p-4 text-center w-full text-lg mt-6 rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
					Check out
				</Link>
			</div>
		)

	}


  return (
    <div className="h-auto bg-gray-100 py-20">
      <h1 className="mb-10 text-center text-2xl font-bold logo">
        Cart Items
      </h1>
      <div className="mx-auto max-w-5xl justify-center flex flex-col-reverse space-y-4 px-6 md:flex md:flex-row md:space-x-6 xl:px-0">
        <div className="rounded-lg md:w-2/3">
          {data}
        </div>
        {totalCard}
      </div>
    </div>
  );
};

export default CartItems;
