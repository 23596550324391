import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Title } from '../../components'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  return (
    <section className="py-12 bg-black flex flex-col space-y-4 md:flex-row md:space-x-4 justify-items-center text-center md:text-left md:justify-around">
			<div className="flex flex-col items-center justify-center">
				<Title name="PahtBar"/>
			</div>
      		<div className="flex flex-col space-y-3">
				<h3 className="text-white font-bold text-lg">Customer Service</h3>
				<div className="flex flex-col">
					<Link className="text-gray-300 text-md" to="/TermsOfServices">
						Terms Of Services
					</Link>
					<Link className="text-gray-300 text-md" to="/PrivacyPolicy">
						Privacy Policy
					</Link>
					<Link className="text-gray-300 text-md" to="/AboutUs">
						About Us
					</Link>
					<Link className="text-gray-300 text-md" to="/Contact">
						Contact Us
					</Link>
				</div>
			</div>
			<div className="flex flex-col space-y-3">
				<h3 className="text-white font-bold text-lg">Contact Us</h3>
				<div className="flex flex-col">
					<p className="text-gray-300 text-md">
						<FontAwesomeIcon icon={faPhone} /> Phone
					</p>
					<p className="text-gray-300 text-md">
						<FontAwesomeIcon icon={faFacebook} /> PahtBar
					</p>
					<p className="text-gray-300 text-md">
						<FontAwesomeIcon icon={faInstagram} /> PahtBar
					</p>
					<p className="text-gray-300 text-md">
						<FontAwesomeIcon icon={faWhatsapp} /> whatsapp
					</p>
				</div>
			</div>
			{/* <div className="flex flex-col space-y-3">
				<h3 className="text-white font-bold text-lg">Address</h3>
				<div className="flex flex-col">
					<p className="mb-2 text-gray-300">
						81 Poulton Street Kirkham.
					</p>
					<a href="mailto:phatbar.official@gmail.com" className="mb-2 text-gray-300">
						click here to send us email
					</a>
					
				</div>
			</div> */}
    </section>
  )
}

export default Footer