import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CartItem = (props) => {


	const navigate = useNavigate();

	const removeItem = async () => {
      try {
        const response = await axios.post('https://phatbar.store/api/api/remove-cart-item', {
          user_id: props.user_id, // Replace with the actual user ID
          name: props.username, // Replace with the actual user name
					cart_item_id: props.cartItemId
        }, {
          withCredentials: true,
        });;
				console.log(response);
				if(response.data.status === 200)
				{
					await Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Cart Item has been deleted',
						showConfirmButton: false,
						timer: 1500
					});
					navigate('/');
				}
      } catch (error) {
        console.error('Error fetching data:', error);
      }
	}
  return (
		<div class="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
			<img alt="test" src={props.image} class="w-full rounded-lg sm:w-40" />
			<div class="sm:ml-4 sm:flex sm:w-full sm:justify-between">
				<div class="mt-5 sm:mt-0 flex flex-col justify-items-center items-center justify-center">
					<h2 class="text-xl text-gray-900">{props.name}</h2>
					{/* <p class="mt-1 text-xs text-gray-700">{props.desc}</p> */}
				</div>
				<div class="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
					<div className="flex items-center border-gray-100">
						<p class="mt-1 text-xs text-gray-700">quantity x {props.quantity}</p>
					</div>
					<div class="flex items-center space-x-4">
						<div className="flex flex-col space-y-4">
							<del class="text-sm text-red-600">{props.old_price ? '£':''}{props.old_price ? parseFloat(props.old_price).toFixed(2) : null}</del>
							<p class="text-sm">£{props.price}</p>
						</div>
						<svg onClick={removeItem} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CartItem